module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Silbato","short_name":"Silbato","start_url":"/","background_color":"#DF411F","theme_color":"#DF411F","display":"minimal-ui","icon":"src/images/silbato.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Work Sans:300,400,500,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W56MGRN","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"web"}},"routeChangeEventName":"route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/fvera/StrideCode/silbato/web/src/intl","languages":["en","es"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/Users/fvera/StrideCode/silbato/web/src/components/redirect.tsx"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
