// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alarma-de-emergencias-y-eventos-sospechosos-tsx": () => import("./../src/pages/alarma-de-emergencias-y-eventos-sospechosos.tsx" /* webpackChunkName: "component---src-pages-alarma-de-emergencias-y-eventos-sospechosos-tsx" */),
  "component---src-pages-alarma-vecinal-y-boton-de-panico-tsx": () => import("./../src/pages/alarma-vecinal-y-boton-de-panico.tsx" /* webpackChunkName: "component---src-pages-alarma-vecinal-y-boton-de-panico-tsx" */),
  "component---src-pages-contact-for-partnership-tsx": () => import("./../src/pages/contact-for-partnership.tsx" /* webpackChunkName: "component---src-pages-contact-for-partnership-tsx" */),
  "component---src-pages-contacto-para-alianzas-tsx": () => import("./../src/pages/contacto-para-alianzas.tsx" /* webpackChunkName: "component---src-pages-contacto-para-alianzas-tsx" */),
  "component---src-pages-cotiza-silbato-tsx": () => import("./../src/pages/cotiza-silbato.tsx" /* webpackChunkName: "component---src-pages-cotiza-silbato-tsx" */),
  "component---src-pages-descarga-silbato-app-tsx": () => import("./../src/pages/descarga-silbato-app.tsx" /* webpackChunkName: "component---src-pages-descarga-silbato-app-tsx" */),
  "component---src-pages-dispositivo-alarma-empresarial-tsx": () => import("./../src/pages/dispositivo-alarma-empresarial.tsx" /* webpackChunkName: "component---src-pages-dispositivo-alarma-empresarial-tsx" */),
  "component---src-pages-download-whistle-app-tsx": () => import("./../src/pages/download-whistle-app.tsx" /* webpackChunkName: "component---src-pages-download-whistle-app-tsx" */),
  "component---src-pages-emergency-and-suspicious-events-alarm-app-tsx": () => import("./../src/pages/emergency-and-suspicious-events-alarm-app.tsx" /* webpackChunkName: "component---src-pages-emergency-and-suspicious-events-alarm-app-tsx" */),
  "component---src-pages-enterprise-alarm-device-tsx": () => import("./../src/pages/enterprise-alarm-device.tsx" /* webpackChunkName: "component---src-pages-enterprise-alarm-device-tsx" */),
  "component---src-pages-gabinete-silbato-sistema-de-alarma-inteligente-tsx": () => import("./../src/pages/gabinete-silbato-sistema-de-alarma-inteligente.tsx" /* webpackChunkName: "component---src-pages-gabinete-silbato-sistema-de-alarma-inteligente-tsx" */),
  "component---src-pages-get-a-quote-tsx": () => import("./../src/pages/get-a-quote.tsx" /* webpackChunkName: "component---src-pages-get-a-quote-tsx" */),
  "component---src-pages-gracias-por-contactarnos-tsx": () => import("./../src/pages/gracias-por-contactarnos.tsx" /* webpackChunkName: "component---src-pages-gracias-por-contactarnos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-precios-tsx": () => import("./../src/pages/precios.tsx" /* webpackChunkName: "component---src-pages-precios-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-thank-you-for-contacting-us-tsx": () => import("./../src/pages/thank-you-for-contacting-us.tsx" /* webpackChunkName: "component---src-pages-thank-you-for-contacting-us-tsx" */),
  "component---src-pages-whistle-horn-smart-alarm-system-tsx": () => import("./../src/pages/whistle-horn-smart-alarm-system.tsx" /* webpackChunkName: "component---src-pages-whistle-horn-smart-alarm-system-tsx" */)
}

